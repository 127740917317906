
  *{
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        /* background-color: #ffffff; */
        
    }
    /* ::-webkit-scrollbar {
        width: 0; 
        background: transparent;  
    } */
    /* .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn { */
        /* color: #cab8ae; */
        /* font-weight: 500; */
    /* } */
    /* .ant-tabs-ink-bar { */
        /* position: absolute; */
        /* background: #f67225; */
        /* pointer-events: none; */
    /* } */
   